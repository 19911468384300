underline {
  text-decoration: underline;
}

header {
  height: 86px;
}

@media screen and (max-width: 991px) {
  header {
    height: 59px;
  }
}

.navbar.fixed-top {
  //background-color: #fff;
}