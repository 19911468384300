// font-family: 'Barlow Condensed', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@300;400;500;600;700;800&display=swap');

// font-family: 'Teko', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@400;700&display=swap');

//font-family: 'Noto Sans HK', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+HK:wght@400;700&display=swap');

//colors
$dark-hilight-color: #b13d4a;
$hilight-color: #e1627e;
$light-hilight-color: #e88097;

body,  
html {  
    height: 100%; 
    font-family: 'Noto Sans HK', sans-serif;
    line-height: 1.9em;
}

h1, h2, h3, h4, h5, h6, h7, h8 {
    font-family: 'Teko', sans-serif; 
    line-height: 1.2em !important;
}

.header-accent {
    line-height: 1em !important;
}

h2 {
    font-weight: bold !important;
}

.h3-enhanced {
    color: $hilight-color;
    font-style: italic;
}

a.nav-link {
    &.active {
        text-decoration: underline !important;
    }
}

@media screen and (max-width: 991px) {
	.top-nav .nav-link {
		color: #000 !important;
		/* background: rgba(255,255,255, 0.5); */
		padding: 1px 6px;
		border-radius: 5px;
		border: 1px solid #000;
		/* margin-right: -110px; */
		position: absolute;
		right: 70px;
	}
}

#breadcrumb-nav {
    background-color: #000;
    font-family: 'Asap Condensed', sans-serif;
    font-size: 0.85rem;

    position: fixed;
    width: 100%;
    z-index: 99;

    a {
        color: #fff;
    }

    ol {
        margin-bottom: 0px; 
    }

    li {
        color: #fff !important;

        &:before {
            color: #fff !important;
        }
    }
}

.hilight {
    color: $hilight-color !important;
}

.dark-hilight {
    color: $dark-hilight-color !important;
}

.light-hilight {
    color: $light-hilight-color !important;
}

.normal-weight {
    font-weight: normal !important;
}

.breadcrumb-spacing {
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-position: top;
    background-image: url('../assets/backgrounds/cherry_bottom.jpg');
}

#membership-nav, #info-nav { 
    font-family: 'Barlow Condensed', sans-serif !important;
    text-transform: uppercase;
    letter-spacing: 2px;
}

#membership-nav { 
    font-weight: 700;
    font-size: 1.2em;

    .nav-link {
        color: rgba(0,0,0,1);

        &:hover {
            color: rgba(0,0,0,0.5);
        }
    }
}

#info-nav {
    font-weight: 500; 
    color: #818181;
}

#navbar-logo {
    width: 275px;
}

@media screen and (max-width: 991px) {
    #navbar-logo {
        width: 150px;
    }
}


.navbar {
    box-shadow: 2px 2px 7px rgba(0,0,0,0.2);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../assets/backgrounds/cherry_top4.jpg');
}

// navbar icon
.navbar-light .navbar-toggler {
    color: rgba(0,0,0,.80);
    border-color: rgba(0,0,0,.8);
}

.navbar-toggler {
    padding: 0.25rem 0.55rem;
    font-size: 1rem;
}

// navbar text color
.navbar-light .navbar-nav .nav-link {
    color: rgba(0,0,0,.9);
}

//jumbotron
.jumbo-left, .jumbo-right {
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0px;
}

.banner-bg {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
}

.banner-content {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    color: #fff;
    padding: 6em 4em; 
}

#home-banner {
    width: 100%;

    .LineHeight35 {
        line-height: 3.2rem !important;
    }

    .carousel-bg {
        height: 511px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right bottom;
    }
    
    .text-box {
        text-align: center;
    }
    
    .text1 {
        color: #fff;
        text-transform: uppercase;
        font-size: 3.7rem;
    }

    .text2 {
        color: #e1627e;
        text-transform: uppercase;
        font-size: 4rem;
        font-weight: bold;
        margin-top: -20px;
    }

    .text2-2 {
        @extend .text2;
        margin-top: -13px;
    }

    @media screen and (max-width: 1199px) {
        .text-box {
            text-align: left;
        }
    }

    @media screen and (max-width: 991px) {
        .text1, .text2 {
            line-height: 3.3rem !important;
        }

        .text2, .text2-2 {
            margin-top: -8px;
        }

        .carousel-bg {
            background-size: 65%;
            background-position: right bottom;
        }
    }

    @media screen and (max-width: 767px) {
        .text-box {
            text-align: center;
            margin-top: -20px;
        }

        .image-box {
            display: none;
        }

        .LineHeight35 {
            line-height: 2.3rem !important;
        }

        .text1 {
            font-size: 2.8rem;
            line-height: 2.8rem;
        }

        .text2 {
            font-size: 3rem;
            margin-top: -20px;
        }

        .text2-2 {
            margin-top: -13px;
        }

        .carousel-bg {
            background-size: 80%;
            background-position: center center;
        }

        .col-md-6 {
            position: relative;
            z-index: 2;
        }

        .carousel-control-prev, .carousel-control-next {
            z-index: 3;
        }

        .bg-overlay {
            width: 100%;
            height: 463px;
            background-color: rgba(0,0,0,0);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }

        .cta-buttons {
            position: absolute;
            top: 367px;
            width: 100%;
            margin-left: -20px;
        }
    }
}

#membership-push {
    width: 100%;

    .banner-bg {
        background-color: rgba(249,243,237, 1); 
    }

    .banner-content {
        color: #000;
    }

    .jumbo-left {
        background-image: url('../assets/home/share-and-discover.jpg');
        background-size: cover;
        background-position: center;
    }

    .jumbo-left-about {
        background-image: url('../assets/home/banner2-b.jpg');
        background-size: cover;
        background-position: center;
    }
}

.cta-buttons {
    margin: 0;
}

#membership-gift {
    .banner-bg {
        background-color: #fff;
    }

    .banner-content {
        color: #000;
    }

    .jumbo-right {
        background-image: url('../assets/home/gift.jpg');
        background-size: cover;
        background-position: center;
    }
}

#membership-why {
    .section-content {
        background-color: rgb(249,243,237);
    }
}

footer {
    background-color: #000;
}

.feature {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
    font-size: 1.5rem;
}

.bg-featured-blog {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 15rem;
}

.text-link {
    color: #000;

    &:hover {
        color: #2ac800;
    }
}

.display-7 {
    font-size: 2.0em;
}

.display-8 {
    font-size: 1.4em;
}

.btn-cta {
    border: 0px;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 0.9em;
    margin-top: 5px;
}


/* CSS */
.btn-cta {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(100% 100% at 100% 0, $light-hilight-color 0, $hilight-color 100%);
  border: 0;
  border-radius: 13px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  display: inline-flex;
  height: 40px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 36px;
  padding-right: 36px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  padding-bottom: 10px;
}

.btn-cta:focus {
  box-shadow: #e28fde 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #e28fde 0 -3px 0 inset;
}

.btn-cta:hover {
  box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #80527d 0 -3px 0 inset;
  transform: translateY(-2px);
}

.btn-cta:active {
  box-shadow: #e28fde 0 3px 7px inset;
  transform: translateY(2px);
}

.section-content {
    text-align: center;
    padding: 4em;    
}

h2 {
    text-transform: capitalize;
    color: $hilight-color;
    font-weight: 900;
    line-height: 1.3em;

    .header-accent {
        font-style: italic;
        color: #000;
        font-size: 1.5em;
    }
}

h3 {
    text-transform: capitalize;
    font-weight: bold;

    .header-accent {
        color: $hilight-color;
    }
}

.header-description {
    font-weight:bold;
}

.point {
    //margin: 0 3em;

    img {
        height: 100px;
    }
}

#hiw, #subscription-plans, #box-selection, #introductory-sets, #subscription-card-form {
    h1 {
        color: $hilight-color;
        font-weight: bold;
        text-transform: uppercase;
    }
}

#hiw, #hiw-days {
    .right-content {
        background-image: url('../assets/vertical-divider.png'); 
        background-repeat: repeat-y;
    }
}

#hiw-days {
    h4 {
        color: #2ac800;
        font-size: 3em;
        font-weight: bold;
    }

    .left-content {
        line-height: 0em;
    }

    .price-end {
        font-weight: bold;
        text-transform: uppercase;        
    }

}

.payment-discounts {
    img {
        max-width: 431px;
    }
}

.payment-amount {
    .value {
        font-family: 'Asap Condensed', sans-serif;
        font-size: 5em;
        line-height: 1em;
        font-weight: bold;
        color: #6c6c6c;
    }
}

.payment-header {
    text-transform: uppercase;
    font-family: 'Asap Condensed', sans-serif;
    font-weight: bold;
    font-size: 1.6em;
    background-color: #844e00;
    color: #fff;
    padding: 0.5em;

    &.active {
        background-color: #009100;
    }
}

.img-holder {
    img {
        max-width: 800px;
    }
}

#embed-subscribe-form {
    margin-top:-180px;
}

@media screen and (max-width: 991px) {
    .subscription-plan { 
        .spacer {
            height: 0px;
        }
    }

    #membership-gift, #membership-why {
        margin-top: 0px !important;
    }

    #membership-push {
        .jumbo-left {
            display: none;
        }

        .jumbo-right {
            background-image: url(../assets/home/share-and-discover.jpg);
            background-size: cover;
            background-position: center;
        }

        .banner-bg {
            background-color: rgba(249,243,237, 0.9); 
        }
    }

    #home-banner {
        .jumbo-right {
            display: none;
        }
    }

    .points {
        .point {
            margin-bottom: 3em;
        }
    }
}

@media screen and (max-width: 800px) {
    #embed-subscribe-form {
        margin-top:-23%;
    }

    .img-holder {
        img {
            width: 100%;
        }
    }

    #membership-gift {
        .jumbo-right {
            display: none;
        }
    }

    #membership-why {
        background-image: url(../assets/home/gift.jpg);
        background-size: cover;
        background-position: center;

        .section-content {
            background-color: rgba(249,243,237,0.9);
        }
    }
}

@media screen and (max-width: 767px) {
    .monthly-delivery {
        .left-content {
            margin-bottom: 2em;

            img {
                width: 200px;
            }
        }
        .right-content {
            background: none !important;
        }
    }
}

@media screen and (max-width: 640px) {
    .banner-content {
        padding: 3em 2em;
    } 
    .section-content {
        padding: 3em 0.5em;
    }

    #subscription-plans {
        .section-content {
            padding: 3em 0em;
        }
    }

    .points {
        .point {
            margin: 1em 0em;
        }
    }
}

@media screen and (max-width: 575px) {
    #hiw, #hiw-days {
        .right-content {
            background: none !important;
        }
    }

    .section-content {
        .section-info {
            border-bottom: 1px solid #ccc;
            margin-bottom: 3em;

            .left-content {
                h3 {
                    font-size: 3em;
                }
            }

            .right-content {
                margin-top: 3em;
            }
        }
    }

    .btn-cta {
        font-size: 1.0em; 
    }
}

.glow-on-hover {
    border: 1px solid $hilight-color;
    color: $hilight-color;
    outline: none;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: bold;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #940080, #fd3df4, #5b3480, #f1adec, #732f85, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:active {
    color: #000;
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:hover {
    color: $hilight-color;
}
.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

.force-uppercase {
    text-transform: uppercase !important;
}

.liquor-law {
    line-height: 20px;
    background-color: $dark-hilight-color;
    color: #fff;
    font-size: 10px;
    text-align: center;
    margin-top: 46px;
    padding: 3px 20px;

    &.no-top-margin {
        margin-top: 0px !important;
    }
}

@media screen and (max-width: 640px) {
    .liquor-law.checkout {
        margin-top: 77px;
    }
}