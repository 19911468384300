.cart-item {
  .img-container {
    cursor: pointer;
    width: 100px;

    img {
      width: 100%;
      min-width: 40px;
    }
  }

  .item-title {
    font-weight: bold;
  }

  .item-subtitle {
    font-size: 0.8rem;
  }

  .item-quantity {
    width: 70px;
    
    input {
      padding: 3px 3px 3px 10px;
    }
  }

  .item-price {
    font-size: 0.7rem;
    line-height: 1rem;
    color: grey;
  }

  .cart-sub-total {
    text-align: right;
    width: 130px;
    font-size: 0.9rem;
  }

  .cart-button {
    background: none;
    border: 1px solid #ccc;
    padding: 0px 10px;
  }

  .cart-quantity {
    text-align: center;
  }

  .item-brewery {
    font-style: italic;
  }

  .item-name {
    line-height: 1.2rem;
    font-size:0.8rem;
  }

  .inner-item-name {
    font-size: 0.9rem;  
    font-weight: bold;
    cursor: pointer;
  }

  .cart-mobile-buttons {
    float: right;
    margin-top: 15px;
  }
}