//colors
$sharp-hilight-color: #ca0000;
$dark-hilight-color: #b13d4a;
$hilight-color: #e1627e;
$light-hilight-color: #e88097;
$x-light-hilight-color: #ffceef;

.card-container {
  padding-left: 0px;
  padding-right: 0px;
}

@media screen and (min-width: 992px) {
  .card-container {
    margin-bottom: 2em;
  }
}

@media screen and (max-width: 991px) {
  .card-container {
    margin-bottom: 20px;
  }
}

.btn-choose {
  text-transform: capitalize;
}

.strike-original-price {
  color: #ccc;
  font-size: 0.7rem;
  text-decoration: line-through;
  font-weight: bold;
  margin-left: 10px;
}

.discounted-price {
  color: $dark-hilight-color;
  text-shadow: 2px 2px 3px $x-light-hilight-color;
}

.discount-badge {
  border-radius: 0px;
  background-color: $sharp-hilight-color !important;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-right: 10px;
}