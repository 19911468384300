//colors
$dark-hilight-color: #b13d4a;
$hilight-color: #e1627e;
$light-hilight-color: #e88097;

.footer-text {
  font-size: 0.7rem;
  line-height: 1.3rem;
}

.footer-list {
  font-size: 0.7rem;
  line-height: 1.3rem;
  padding-left: 5px;

  .list-group-item {
    background: transparent;
    color: #fff;
    padding-left: 5px;
    padding-top: 4px;
    padding-bottom: 4px;

    a {
      color: #fff;
      text-decoration: none;

      &:hover {
        color: $light-hilight-color;
      }
    }
  }

  .list-group-item::before {
    content: "»";
    padding-right: 10px;
    font-size: 1rem;
  }
}