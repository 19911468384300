@keyframes showBg { 
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.modal-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
  z-index: 10000;
  animation: showBg 0.5s ease-out;
}

@keyframes showModal {   
  0% {
    top: 8%;
  }

  100% {
    top: 10%;
  }
}

.cart-modal {
  position: fixed;
  top: 10%;  
  width: 100%;
  z-index: 10001;
  animation: showModal 0.5s ease-out;
}

@media screen and (max-width: 575px) {
  @keyframes showModal {   
    0% {
      top: 5px;
    }
  
    100% {
      top: 10px;
    }
  }

  .cart-modal {
    top: 10px;
  }
}