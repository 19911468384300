.product {
  margin: 10px;
  margin-bottom: 100px !important;
  width: 400px;

  &.set-details {
    width: inherit;
    margin-bottom: 30px !important;
  }

  @media screen and (min-width: 576px) {
    max-height: 252px;

    &.set-details {
      max-height: inherit;
    }
  }

  .card-title {
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 0.9rem;
    font-weight: bold;
  }

  .card-body {
    cursor: pointer;
    @media screen and (min-width: 576px) {
      height: 250px;
    }
  }

  .card-footer {
    text-align: right;
    font-size: 0.8rem;

    button {
      line-height: 0.7rem;
      padding-bottom: 0.3rem;
    }
  }

  .image {
    text-align: center;
    cursor: pointer;
    
    img {
      width: 100%;
      @media screen and (max-width: 575px) {
        width: 132px;
      }
      max-width: 132px;
      height: 250px;    
    }
  }

  .more-info {
    font-size: 0.8rem;
  }
}


.product-table-display {

  .image-container {
    width: 50px;
    vertical-align: top;
  }

  .product-info {
    vertical-align: top;
    padding-bottom: 20px;
  }

  img {
    width: 50px;
  }
}