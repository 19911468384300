
@media screen and (max-width: 640px) {
  #subscription-card-form.breadcrumb-spacing {
    // margin-top: 72px; 
  }
}

.products-container {
  overflow-y: auto;
  max-height: 50vh;
}