.single-product-info {
  .image {
    background-size: cover;
    background-position: center;
    min-height: 400px;
    max-height: 600px;
  }
}

.brewery {
  text-align: center;
  .image {
    background-size: contain;
    background-position: center;
    height: 150px;
    background-repeat: no-repeat;
  }
}

.prefecture {
  text-align: center;

  .image {
    background-size: contain;
    background-position: center;
    height: 260px;
    background-repeat: no-repeat;
  }
}

.sake-facts {
  .chart-container {
    background-image: url("https://justsake-images-production.s3.ap-southeast-1.amazonaws.com/images/smv-acidity-chart-overlay-light.gif");
    background-position: center;
    background-size: 100% 100%;
  }

  canvas {
    height: 250px !important;
  }

  .facts-container {
    strong {
      color: rgb(225, 98, 126);
    }
  }
}